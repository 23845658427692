import React from "react";
import ReactMarkdown from "react-markdown";
import { graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import Layout from "../components/layout";

import * as styles from "./index.module.css";
import content from "./index.content.yml";

export default function IndexPage({ data }) {
  const imgsByPath = data?.images?.edges?.reduce(
    (imgMap, { node }) => ({
      ...imgMap,
      [`/src/${node.relativePath}`]: node,
    }),
    {}
  );

  return (
    <Layout>
      <main>
        <section id="top" className={styles.top}>
          <GatsbyImage
            alt="Erin posing with the pack"
            className={styles.leftFloatingImgWrapper}
            image={getImage(imgsByPath[content.top.image])}
          />
          <ReactMarkdown>{content.top.copy}</ReactMarkdown>
        </section>
        <section id="middle">
          <GatsbyImage
            alt="Erin and the pack in action"
            className={styles.rightFloatingImgWrapper}
            image={getImage(imgsByPath[content.middle.image])}
          />
          <ReactMarkdown>{content.middle.copy}</ReactMarkdown>
        </section>
        <section id="bottom" className={styles.bottom}>
          <GatsbyImage
            alt="About"
            className={styles.leftFloatingImgWrapper}
            image={getImage(imgsByPath[content.bottom.image])}
          />
          <ReactMarkdown>{content.bottom.copy}</ReactMarkdown>
        </section>
      </main>
    </Layout>
  );
}

export const query = graphql`
  query IndexQuery {
    images: allFile(filter: { relativeDirectory: { eq: "images" } }) {
      edges {
        node {
          relativePath
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
          }
        }
      }
    }
    site {
      siteMetadata {
        calendly
        social
      }
    }
  }
`;
